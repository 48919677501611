.App-intro {
    height: 100%;
    margin: 10vw;
    color: white;
}

.App {
    text-align: center;
}

.App-header nav {
    width: 100%;
    padding: 1rem;
}

.amplify-menu-trigger {
    float: right;
    display: none;
    background: white;
}

@media screen and (min-width: 1108px) {
    .App-header .amplify-button {
        float: right;
        height: 50px;
        margin: 0.5rem 0 0 0.5rem;
    }

    .hp-role {
        color: black;
        background-color: cyan;
    }

    .admin-role {
        color: white;
        background-color: magenta;
    }
}
@media screen and (max-width: 1107px) {
    .App-header nav {
        min-width: 400px;
        width: 100%;
        padding: 1rem;
        height: 140px;
    }

    .amplify-menu-trigger {
        display: none;
        margin: 0.5rem 0 0 0.5rem;
    }

    .App-header .amplify-button {
        float: right;
        height: 50px;
        margin: 0.5rem 0 0 0.5rem;
    }

    .hp-role {
        color: black;
        background-color: cyan;
    }

    .admin-role {
        color: white;
        background-color: magenta;
    }
}

@media screen and (max-width: 760px) {
    .App-header nav {
        display: none;
    }

    .amplify-menu-trigger {
        display: flex;
        margin: 0.5rem 0 0 0.5rem;
    }

    .App-header .amplify-button--menu {
        float: right;
        margin: 0;
    }

    .App-header .amplify-button.hp-role {
        color: black;
        background-color: cyan;
        margin: 0;
    }

    .admin-role {
        color: white;
        background-color: magenta;
        margin: 0;
    }
}

.App-error {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.App-body .amplify-flex {
    flex-direction: column;
}

.App-body .flex-page {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.App-body .flex-row {
    flex-direction: row;
    justify-content: center;
}

.App-body .flex-row-fill {
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
}

.App-body {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.App-body .amplify-card {
    text-align: left;
    width: 24rem;
}

.App-body .userinfo-cards {
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
}

.amplify-checkboxfield label {
    flex-direction: row-reverse !important;
}

.amplify-radiogroupfield label {
    flex-direction: row-reverse !important;
}

.App-body .amplify-button {
    width: auto;
}

.App-body .amplify-table {
    background: white;
    border: 0px;
    border-radius: var(--amplify-radii-small);
}

.App-body .amplify-table__td {
    border: 0px;
}
.App-body .amplify-table__th {
    border: 0px;
}

.App-header .top-bar {
    display: flex;
    align-items: center;
}

.App-header .logo {
    float: left;
    margin: 0.5rem 1rem 0 1rem;
}

.App-header .amplify-menu {
    float: right;
    height: 50px;
    margin: 0.5rem 0 0 0.5rem;
}


.active_route .amplify-button {
    background-color: var(--amplify-components-button-primary-focus-background-color);
}


.logout-button {
    color: white;
    background-color: darkred;
}

.App-body .table-action-button {
    margin: 0 0.5rem 0 0;
}

.App-body .submit-button {
    margin: 0.5rem 0 0 0;
    width: 100%;
}

.App-body .amplify-radiogroupfield {
    margin: 0 0 1rem 0;
}

.App-body .amplify-textfield {
    margin: 0 0 0 0;
}

.App-header .amplify-selectfield {
    float: right;
    margin: 0.5rem 0 0 0.5rem;
}

.App-header .amplify-select {
    float: right;
    height: 50px;
    background-color: lightcyan;
}

.App-header .amplify-select__icon-wrapper {
    color: black;
}


.a--white[data-v-55d38626] {
    fill: #fff;
}

.c[data-v-55d38626] {
    fill: #b64169;
}

h1,h2,h3 {
    color: white;
    margin: 0;
}

.w-64 {
    width: 16rem !important;
}
.h-full {
    height: 100% !important;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
